
    import { defineComponent } from 'vue';
    import { chevronBackOutline } from 'ionicons/icons';
    import { LanguageMixin } from '@/mixins/LanguageMixin';
    import { SharedMixin } from '../../mixins/SharedMixin';
    import { store } from '../../store/store';
    import { MemberApiClient, GeneralApiClient } from '../../generated';

    import {
        IonImg,
        IonInput,
        IonItem,
        IonLabel,
        IonButton,
    } from '@ionic/vue';

    export default defineComponent({
        name: 'Register',
        mixins: [SharedMixin, LanguageMixin],
        components: {
            IonImg,
            IonInput,
            IonItem,
            IonLabel,
            IonButton,
        },
        setup() {
            return {
                chevronBackOutline
            }
        },
        data() {
            return {
                fboNumber: '',
                zipCode: '',
                emailSuccess: false,
                email: '',
                errorMessage: false,
                registerLink: '',
                registerLinkText: ''
            }
        },
        methods: {
            validateAndSendEmail: function () {
                console.log(`Input FBO number "${this.fboNumber}" and zipcode "${this.zipCode}"`)

                const client = new MemberApiClient();
                client.registerMember(this.fboNumber, this.zipCode, store.state.currentLanguage).then(result => {
                    if (result) {
                        this.email = result;
                        this.emailSuccess = true;
                        this.errorMessage = false;
                    } else {
                        // foutmelding tonen, willen we hier nog soorten foutmeldingen? Member bestaat niet als FBO / Member bestaat al?
                        this.errorMessage = true;
                    }
                });
            }
        },
        mounted() {
            const generalApiClient = new GeneralApiClient();
            generalApiClient.getRegisterLink(store.state.currentLanguage).then(
                result => {
                    this.registerLink = result ?? '';
                });
            generalApiClient.getRegisterLinkText(store.state.currentLanguage).then(
                result => {
                    this.registerLinkText = result ?? '';
                });

            if (localStorage.getItem('loggedIn') == 'true') {
                this.$router.push('/home').then(
                    () => {
                        window.location.reload();
                    }
                );
            }
        }
    });
